
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query VcsListQuery($limit: Int!, $offset: Int!, $project_id: Int!) {
    project_items(limit: $limit, offset: $offset, where: {project_id: {_eq: $project_id}}, order_by: {id: desc}) {
        agent_at
        agent_count
        agent_serial_number
        id
        mint_status
        project_id
        project {
          collection_id
        }
        item_no
        ai_score
        right_status
    }
    project_items_aggregate (where: {project_id: {_eq: $project_id}}){
      aggregate {
        count
      }
    }
  }
`

function fetchVcsListQuery(limit: number, offset: number, projectId: string) {
  return fetchGraphQL(operation, 'VcsListQuery', { limit: limit, offset: offset, project_id: projectId })
}

export default fetchVcsListQuery
