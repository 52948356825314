



















import { Vue, Component, Prop } from 'vue-property-decorator'
import { handleClipboard } from '@/utils/clipboard'

@Component({
  name: 'LensSpan'
})
export default class extends Vue {
  @Prop({ default: '' }) private label!: string
  private visible = false

  private copyLabel(event: MouseEvent) {
    handleClipboard(this.label, event)
    this.visible = false
  }
}
