
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query GetUserApiQuery($user_did: name!) {
    users(where: {user_did: {_eq: $user_did}}) {
      id
      user_account
    }
  }
`

function fetchGetUserApiQuery(userDid: any) {
  return fetchGraphQL(operation, 'GetUserApiQuery', { user_did: userDid })
}

export default fetchGetUserApiQuery
