
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query GetCollectionIdQuery($id: Int!) {
    projects_by_pk(id: $id) {
      collection_id
    }
  }
`

function fetchGetCollectionIdQuery(id: any) {
  return fetchGraphQL(operation, 'GetCollectionIdQuery', { id: id })
}

export default fetchGetCollectionIdQuery
