








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'Empty'
})
export default class extends Vue {
  @Prop({ default: '' }) private description!: string
  @Prop({ default: true }) private showButton!: boolean

  private retry() {
    this.$emit('retry')
  }
}
