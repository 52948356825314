
/*
This is an example snippet - you should consider tailoring it
to your service.

Note: we only handle the first operation here
*/

import { fetchGraphQL } from '@/utils/request'

const operation = `
  query GetMintStatusQuery($id: Int!) {
    project_items_by_pk(id: $id) {
      mint_status
    }
  }
`

function fetchGetMintStatusQuery(id: any) {
  return fetchGraphQL(operation, 'GetMintStatusQuery', { id: id })
}

export default fetchGetMintStatusQuery
