










import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'

@Component({
  name: 'Container'
})
export default class extends Vue {
  @Prop({ default: true }) private usePadding !: boolean
  @Prop({ default: 'white' }) private backgroundColor !: string

  get isCollapse() {
    return !AppModule.sidebar.opened
  }
}
