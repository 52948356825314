import axios from 'axios'

const getKey = 'api/qrcode/key'

const service = axios.create({
  baseURL: 'https://kyc.ecaasospoc.com/',
  timeout: 15000
})

export default class QrcodeApi {
  static requestGetKey = (qrcode: string) =>
    service.request<{ key: string }>({
      url: getKey,
      method: 'post',
      data: {
        json: qrcode
      }
    })
}
