
























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'ListTile'
})
export default class extends Vue {
  @Prop({ default: '' }) private leading!: string
  @Prop({ default: '' }) private tail!: string
  @Prop({ default: '50px' }) private height!: string
  @Prop({ default: false }) private slotLeading!: boolean
  @Prop({ default: false }) private slotTail!: boolean
  @Prop({ default: true }) private separate!: boolean
  @Prop({ default: true }) private usePadding!: boolean
  @Prop({ default: '70%' }) private rightWidth!: string
  @Prop({ default: '' }) private padding!: string

  private get paddingDistance() {
    if (this.padding) return this.padding
    return `0 ${this.usePadding ? this.$variables.edgeDistance : 0}`
  }

  private onClick() {
    this.$emit('click')
  }
}
